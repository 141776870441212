/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Kit 2 React components

// Material Kit 2 React examples
// ImageSlider Us page sections
// Routes
// Images
import React, {useEffect, useState} from "react";
import {Config} from "../../utils/Config";
import MKButton from "../../components/MKButton";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";
import {GetProfile, GetTokenHeaders, numFormat} from "../../common/ReusableFunction";
import {CircularProgress} from "@mui/material";
import {AddAlertOutlined} from "@mui/icons-material";
import BusinessTypesModal from "./BusinessTypesModal";
import BusinessTypeFormModal from "./controlNo/businessTypeForm";

function BusinessTypes() {
    let [loading, setLoading] = useState(false);
    let [businessTypes, setBusinessTypes] = useState([]);
    let [businessType, setBusinessType] = useState([]);

    const [open, setOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        getTypes().then(r => false)
    };

    const openModal = (e) => {
        const id = e.target.getAttribute('id');
        const type = businessTypes[id];
        setBusinessType(type);
        console.log(type);
        setIsModalOpen(true);
    }
    const closeModal = () => {
        setIsModalOpen(false);
    }
    const handleUpdateBusinessType = (formData) => {
        const payLoad = {
            id: formData.id,
            description: formData.description,
            current_price: formData.current_price,
            created_by: profile.id
        }
        updateBusinessType(payLoad).then(r => false)
        // Handle form submission, e.g., make an API call
        console.log('Creating subscriber with data:', formData);
        // Close the modal after submission
        closeModal();
    };
    const updateBusinessType = async (payLoad) => {
        setLoading(true)

        const res = await Axios.put(apiURL + 'update-business-type/' + payLoad.id, payLoad, headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 101) {
                AlertMessages(status, message)

            } else {
                alert(message)
            }

            setLoading(false);
            getTypes().then(r => false);

        }
    }

    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    const getTypes = async (payLoad) => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'get-business-types', headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setBusinessTypes(res.data.data.events)
            }

            setLoading(false)
        }
    }
    useEffect(() => {
        getTypes().then(r => false);
    }, []);

    useEffect(() => {
        setBusinessTypes(businessTypes);
    }, [businessTypes]);


    return (
        <div className="bg-white rounded-md mt-3">

            <div className="flex justify-end mr-2 mb-2 mt-3">
                <MKButton color='warning' type='default' onClick={handleOpen}
                          style={{float: 'right', mr: 2}}><AddAlertOutlined/>&nbsp; New
                    Type</MKButton>

            </div>
            {loading && <CircularProgress/>}
            {businessTypes && Number(businessTypes.length) > 0 &&
                <div className="px-2">
                    <div className="text-center text-lg font-bold -mt-5">Business Types List</div>
                    <table className="min-w-full divide-y divide-gray-300 rounded-lg">
                        <thead className="bg-gray-200">
                        <tr>
                            <th className="text-sm px-2 py-1 text-left">SN</th>
                            <th className="text-sm px-2 py-1 text-left">NAME</th>
                            <th className="text-sm px-2 py-1 text-left">DESCRIPTION</th>
                            <th className="text-sm px-2 py-1 text-left">CURRENT PRICE</th>
                            <th className="text-sm px-2 py-1 text-left"></th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-100">
                        {businessTypes && businessTypes.map((type, index) =>
                            <tr key={index} className="bg-gray-50">
                                <td className="text-sm px-2 py-1 text-left">{index + 1}</td>
                                <td className="text-sm px-2 py-1 text-left">{type.name}</td>
                                <td className="text-sm px-2 py-1 text-left">{type.description}</td>
                                <td className="text-sm px-2 py-1 text-right">{numFormat(type.current_price)}</td>
                                <td className="text-sm px-2 py-1 text-left">
                                    <button id={index} onClick={openModal}
                                            className="bg-yellow-600 px-2 py-1 text-xs rounded-lg border-0 text-white hover:bg-yellow-400">Edit
                                    </button>
                                </td>
                            </tr>
                        )
                        }
                        </tbody>
                    </table>

                </div>

            }
            <BusinessTypesModal open={open} handleClose={handleClose}/>
            {isModalOpen && <BusinessTypeFormModal
                isOpen={isModalOpen}
                closeModal={closeModal}
                onSubmit={handleUpdateBusinessType}
                businessType={businessType}
            />}

        </div>
    );
}

export default BusinessTypes;
