import React, {useEffect, useState} from "react";
import {Config} from "../../utils/Config";
import MKButton from "../../components/MKButton";
import Divider from "@mui/material/Divider";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import {CircularProgress, IconButton, TextField} from "@mui/material";
import {ReceiptLongOutlined} from "@mui/icons-material";
import BusinessTypesModal from "./BusinessTypesModal";
import PaymentApprovalModal from "./PaymentApprovalModal";
import CompletedRegistrationSummaryModal from "./CompletedRegistrationSummaryModal";

function PendingApprovals() {
    let [loading, setLoading] = useState(false);
    let [businessTypes, setBusinessTypes] = useState([]);
    const [filteredBusinessTypes, setFilteredBusinessTypes] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const [open, setOpen] = useState(false);
    const [openApproval, setOpenApproval] = useState(false);
    const [documentLinks, setDocumentLinks] = useState([]);
    const [links, setLinks] = useState('');
    const [summary, setSummary] = useState();
    const [openSummary, setOpenSummary] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleOpenApproval = (e) => {
        const id = e.currentTarget.getAttribute('id');

        const typeRow = filteredBusinessTypes[id];
        const linkData = {
            registration_form: `${links}${typeRow.id}`,
            payment_form: typeRow.payment_receipt,
            applicationId: typeRow.id
        }
        setDocumentLinks(linkData);
        setOpen(false);
        setOpenApproval(true);
    };

    useEffect(() => {
        if (open) {
            setDocumentLinks(documentLinks);
        }
    }, [handleOpenApproval]);

    const handleClose = () => {
        setOpen(false);
        setOpenApproval(false);
        getApprovals().then(r => false)
    };

    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    const getApprovals = async (payLoad) => {
        setLoading(true);
        const res = await Axios.get(apiURL + 'get-pending-approvals', headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            if (status === 100) {
                setBusinessTypes(res.data.data.events);
                setFilteredBusinessTypes(res.data.data.events); // Initially set filtered data as all data
                setLinks(res.data.data.link);
                setSummary(res.data.data.summary);
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        getApprovals().then(r => false);
    }, []);

    useEffect(() => {
        setFilteredBusinessTypes(businessTypes);
    }, [businessTypes]);

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);

        const filteredData = businessTypes.filter(
            (type) =>
                type.name.toLowerCase().includes(term) ||
                type.type.toLowerCase().includes(term) ||
                type.reference_no.toLowerCase().includes(term) ||
                type.control_no.toLowerCase().includes(term)
        );
        setFilteredBusinessTypes(filteredData);
    };

    const handleOpenSummary = () => {
        setOpenSummary(true);
    };
    const handleCloseSummary = () => {
        setOpenSummary(false);
    };
    const sumAmounts = () => {
        let total = 0;
        try {
            total = summary.reduce((total, record) => total + parseFloat(record.amount), 0);
        } catch (e) {}
        return total;
    };
    const sumCounts = () => {
        let total = 0;
        try {
            total = summary.reduce((total, record) => total + parseFloat(record.count), 0);
        } catch (e) {}
        return total;
    };

    const formatedNumber = (number) => {
        return Number(number).toLocaleString('en-US', {
            style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2,
        });
    };

    return (
        <>
            <div className="bg-white py-2 ml-2 mr-2 rounded-lg flex justify-end">
                <p className="mb-1 text-right mr-9 text-secondary">
                    Count: <b>{sumCounts()}</b> &nbsp; | &nbsp; Total
                    Amount: <b>{formatedNumber(sumAmounts())} TZS</b> &nbsp; | &nbsp;
                    <MKButton onClick={handleOpenSummary} color='secondary'>View Summary</MKButton>
                </p>
            </div>

            {/* Divider */}
            <Divider/>

            {/* Search Field */}
            <div className="py-4 flex justify-between">
                <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearch}
                    placeholder="Search by name, type, reference no, or control no"
                    className="w-full md:w-1/2"
                />
            </div>

            {/* Loader */}
            {loading && <CircularProgress />}

            {/* Table */}
            {filteredBusinessTypes && Number(filteredBusinessTypes.length) > 0 && (
                <div className="px-2">
                    <table className="min-w-full divide-y divide-gray-200 rounded-lg">
                        <thead className="bg-gray-50">
                        <tr>
                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SN</th>
                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reference</th>
                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Control No</th>
                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                            <th className="px-2 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {filteredBusinessTypes.map((type, index) => (
                            <tr key={type.id}>
                                <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-900">{index + 1}</td>
                                <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-900">
                                    <div>{type.name}</div>
                                    <div className="font-light">{type.type}</div>
                                </td>
                                <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-900">{type.reference_no}</td>
                                <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-900">{type.control_no}</td>
                                <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-900 text-right">{Number(type.current_price).toLocaleString()}</td>
                                <td className="px-2 py-1 whitespace-nowrap text-center">
                                    <IconButton
                                        disabled={loading}
                                        id={index}
                                        onClick={handleOpenApproval}
                                    >
                                        {loading ? (
                                            <CircularProgress size={10} color="primary" />
                                        ) : (
                                            <ReceiptLongOutlined color="warning" />
                                        )}
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Modals */}
            <BusinessTypesModal open={open} handleClose={handleClose} />
            <PaymentApprovalModal open={openApproval} handleClose={handleClose} documentLinks={documentLinks} />
            <CompletedRegistrationSummaryModal summary={summary} open={openSummary} handleClose={handleCloseSummary} />
        </>
    );
}

export default PendingApprovals;
