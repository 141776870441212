/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Kit 2 React components

// Material Kit 2 React examples
// Presentation page sections
// Presentation page components
// Routes
// Images
import {useEffect, useState} from "react";
import routes from "../../routesAdmin";
import EventModal from "./EventModal";
import Divider from "@mui/material/Divider";
import Events from "./Events";
import BusinessType from "./BusinessType";
import AlertMessages from "../../utils/AlertMessage";
import {Config} from "../../utils/Config";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import Axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import PendingApprovals from "./PendingApprovals";
import IncompleteRegistration from "./IncompleteRegistration";
import Dashboard from "./Dashboard";
import Reports from "./Reports";
import Sponsors from "./Sponsors";
import SponsorModal from "./SponsorModal";
import Identifications from "./Identifications";
import IdentificationsModal from "./IdentificationsModal";
import CompletedRegistration from "./CompletedRegistration";
import AllRegistrations from "./AllRegistrations";
import Header2 from "../Presentation/header2";
import LeftPanelAdmin from "../Presentation/leftPanelAdmin";
import ControlNumbers from "./controlNo/ControlNumbers";
import Footer from "../Presentation/footer";
import HeaderAdmin from "../Presentation/headerAdmin";
import Users from "./Users";

function Admin() {
    const {paramId} = useParams();
    const [open, setOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState('dashboard');
    const [currentReportType, setCurrentReportType] = useState('');
    let [events, setEvents] = useState([]);
    let [currentEvent, setCurrentEvent] = useState([]);
    let [sponsors, setSponsors] = useState([]);
    let [loading, setLoading] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [filteredRoutes, setFilteredRoutes] = useState([]);

    const history = useNavigate();

    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    const getEvents = async (payLoad) => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'get-events', headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setEvents(res.data.data.events)
            }

            setLoading(false)
        }
    }

    const getSponsors = async () => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'get-sponsors', headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setSponsors(res.data.data.sponsors)
            }
            setLoading(false)
        }
    }
    const getIdentifications = async () => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'get-sponsors', headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setSponsors(res.data.data.sponsors)
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        getSponsors().then(r => false);
    }, []);

    const getCurrentEvent = async (payLoad) => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'get-current-event', headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setCurrentEvent(res.data.data.events)
            }

            setLoading(false)
        }
    }
    useEffect(() => {
        getEvents().then(r => false);
        getCurrentEvent().then(r => false);
    }, []);

    useEffect(() => {
        setCurrentReportType(currentReportType)
    }, [currentReportType]);

    useEffect(() => {
        setSponsors(sponsors)
    }, [sponsors]);

    useEffect(() => {
        if (profile && profile.id) {
            // history('/login');
        }
    });
    const handleOpen = () => {
        setOpen(true);
    };

    const switchTabs = (e) => {
        const tab = e.currentTarget.getAttribute('id')
        setCurrentTab(tab)
    };

    const switchTab = (tab) => {
        setCurrentTab(tab)
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        switchTab(paramId)
    }, [paramId])
    useEffect(() => {
        switchTab('dashboard')
    }, [])

    useEffect(() => {

        const intervalID = setTimeout(() => {

            let isLogged = false;
            const isLoggedIn = localStorage.getItem("isAdmin");

            if (isLoggedIn === 'true') {
                isLogged = true
            } else {
                localStorage.clear();
                history('/login');
            }

            // Update filteredRoutes when the login status changes
            const updatedRoutes = routes.filter((route) => {
                // If user is logged in, show all routes
                if (isLogged) {
                    return false;
                }

                // Hide routes that require login
                if (route.requireLogin) {
                    return true;
                }

                // Show other routes
                return false;
            });

            setFilteredRoutes(updatedRoutes);
        }, 5000);
        return () => clearInterval(intervalID);
    });

    return (
        <div className="bg-gray-200">
            <HeaderAdmin/>
            <LeftPanelAdmin switchTab={switchTab} currentTab={currentTab}/>
            <div className="pt-16 ml-56 py-6">

                <div className="grid grid-cols-1 mt-2">
                    {/*<DefaultNavbar*/}
                    {/*    routes={routes}*/}
                    {/*>*/}

                    {/*</DefaultNavbar>*/}


                    {currentTab === 'events' &&
                        <Events handleOpen={handleOpen} events={events} getEvents={getEvents}
                                loading={loading}/>
                    }
                    {currentTab === 'control-no' &&
                        <ControlNumbers handleOpen={handleOpen} events={events} getEvents={getEvents}
                                loading={loading}/>
                    }
                    {currentTab === 'business-types' &&
                        <BusinessType handleOpen={handleOpen}/>
                    }
                    {currentTab === 'registrations' &&
                        <AllRegistrations handleOpen={handleOpen}/>
                    }
                    {currentTab === 'approvals' &&
                        <PendingApprovals handleOpen={handleOpen}/>
                    }
                    {currentTab === 'incomplete' &&
                        <IncompleteRegistration handleOpen={handleOpen}/>
                    }
                    {currentTab === 'completed' &&
                        <CompletedRegistration handleOpen={handleOpen}/>
                    }
                    {currentTab === 'dashboard' &&
                        <Dashboard handleOpen={handleOpen} setCurrentTab={setCurrentTab}
                                   setCurrentReportType={setCurrentReportType}/>
                    }
                    {currentTab === 'reports' &&
                        <Reports handleOpen={handleOpen}
                                 currentReportType={currentReportType}/>
                    }
                    {currentTab === 'sponsors' &&
                        <Sponsors/>
                    }
                    {currentTab === 'users' &&
                        <Users/>
                    }
                    {currentTab === 'identifications' &&
                        <Identifications handleOpen={handleOpen} sponsors={sponsors} events={currentEvent}/>
                    }

                    <div sx={{maxHeight: '80vh', overflow: 'auto'}}>
                        {open && currentTab === 'events' &&
                            <EventModal open={open} handleClose={handleClose} getEvents={getEvents}/>
                        }
                        {open && currentTab === 'sponsors' &&
                            <SponsorModal open={open} handleClose={handleClose} getEvents={getCurrentEvent}
                                          events={currentEvent} getSponsors={getSponsors}/>
                        }
                        {open && currentTab === 'identifications' &&
                            <IdentificationsModal open={open} handleClose={handleClose} getEvents={getCurrentEvent}
                                                  events={currentEvent} getIdentifications={getIdentifications}/>
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>

    );
}

export default Admin;
