/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
// ImageSlider Us page sections
// Routes
// Images
import React, {useEffect, useState} from "react";
import {Config} from "../../utils/Config";
import MKButton from "../../components/MKButton";
import Divider from "@mui/material/Divider";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import {
    CircularProgress,
    IconButton,
    Table,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField
} from "@mui/material";
import {EditNoteOutlined} from "@mui/icons-material";
import CompletedRegistrationSummaryModal from "./CompletedRegistrationSummaryModal";
import EditRegistrationModal from "./EditRegistrationModal";

function IncompleteRegistration() {
    let [loading, setLoading] = useState(false);
    let [businessTypes, setBusinessTypes] = useState([]);
    let [summary, setSummary] = useState([]);

    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [links, setLinks] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };
    const handleOpenEdit = (e) => {
        const id = e.currentTarget.getAttribute('id');
        const typeRow = businessTypes.find(item => item.id === id);
        setSelectedRow(typeRow);
        setOpen(false);
        setOpenEdit(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenEdit(false);
        getApprovals().then(r => false)
    };

    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    const getApprovals = async (payLoad) => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'pending-registration', headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setBusinessTypes(res.data.data.events);
                setSummary(res.data.data.summary);
                setLinks(res.data.data.link)
            }

            setLoading(false)
        }
    }
    useEffect(() => {
        getApprovals().then(r => false);
    }, []);

    useEffect(() => {
        setBusinessTypes(businessTypes);
        setSummary(summary);
    }, [businessTypes]);
    useEffect(() => {
        setSelectedRow(selectedRow);
    }, [selectedRow]);
    useEffect(() => {
        setLinks(links);
    }, [links]);

    const sumAmounts = () => {
        let total = 0;
        try {
            total = summary.reduce((total, record) => total + parseFloat(record.amount), 0);
        } catch (e) {

        }
        return total;
    };
    const sumCounts = () => {
        let total = 0;
        try {
            total = summary.reduce((total, record) => total + parseFloat(record.count), 0);
        } catch (e) {

        }
        return total;
    };

    const formatedNumber = (number) => {
        return Number(number).toLocaleString('en-US', {
            style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2,
        });
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchText, setSearchText] = useState('');
    const [sortedColumn, setSortedColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event) => {
        setSearchText(event.target.value);
        setPage(0); // Reset to the first page when searching
    };

    const handleSort = (column) => {
        if (column === sortedColumn) {
            // Toggle sort direction
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedColumn(column);
            setSortDirection('asc');
        }
    };

    const sortedData = [...businessTypes].sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];

        if (aValue === undefined || bValue === undefined) {
            return 0; // Handle undefined values as equal
        }

        if (sortDirection === 'asc') {
            return aValue.localeCompare(bValue);
        } else {
            return bValue.localeCompare(aValue);
        }
    });

    const filteredData = searchText
        ? sortedData.filter((row) => {
            // Customize this filter logic according to your needs
            return (
                row.name.toLowerCase().includes(searchText.toLowerCase()) ||
                row.type.toLowerCase().includes(searchText.toLowerCase()) ||
                row.reference_no.toLowerCase().includes(searchText.toLowerCase())
            );
        })
        : sortedData;

    return (
        <>

            <div className="bg-white py-2 ml-2 mr-2 rounded-lg" sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <MKTypography sx={{mb: 1, textAlign: 'right !important', mr: 9}} color='secondary' variant="body2">
                    Count: <b>{sumCounts()}</b> &nbsp; | &nbsp; Total
                    Amount: <b>{formatedNumber(sumAmounts())} TZS</b> &nbsp; | &nbsp;
                    <MKButton onClick={handleOpen} color='secondary'>View Summary</MKButton>
                </MKTypography>
            </div>
            <div sx={{display: 'flex', justifyContent: 'flex-start'}}>
                <TextField sx={{ml: 10, mt: -6}}
                           label="Search"
                           variant="outlined"
                           value={searchText}
                           onChange={handleSearch}
                />
            </div>
            <div className="px-2">

                    {loading && <CircularProgress/>}
                    {businessTypes && Number(businessTypes.length) > 0 &&
                        <div className="px-2">

                            <TableContainer>
                                <Table aria-label="simple table">
                                    <MKBox color='dark' component="thead">
                                        <TableRow>
                                            <TableCell>
                                                <b>SN</b>
                                            </TableCell>
                                            <TableCell onClick={() => handleSort('name')}>
                                                <b>NAME</b>
                                            </TableCell>
                                            <TableCell onClick={() => handleSort('type')}>
                                                <b>BUSINESS TYPE</b>
                                            </TableCell>
                                            <TableCell onClick={() => handleSort('reference_no')}>
                                                <b>REFERENCE</b>
                                            </TableCell>
                                            <TableCell onClick={() => handleSort('control_no')}>
                                                <b>CONTROL_NO</b>
                                            </TableCell>
                                            <TableCell>
                                                <b>REGISTRATION DATE</b>
                                            </TableCell>

                                            <TableCell align="center">
                                                <b>AMOUNT</b>
                                            </TableCell>
                                            <TableCell align="center">
                                            </TableCell>
                                        </TableRow>
                                    </MKBox>
                                    <MKBox color='text' component="tbody">
                                        {filteredData
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((type, index) =>
                                                <TableRow color='dark' key={type.id}>
                                                    <TableCell component="th" scope="row">
                                                        <MKTypography color='secondary' variant="body2"
                                                                      fontWeight="small"
                                                                      textTransform="capitalize"
                                                                      sx={{mb: 0, mt: 0}}>
                                                            {index + 1}
                                                        </MKTypography>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <MKTypography color='dark' variant="body2"
                                                                      fontWeight="small"
                                                                      textTransform="capitalize"
                                                                      sx={{mb: 0, mt: 0}}>
                                                            {type.name}
                                                            <div className="text-sm font-light">{type.phone}</div>
                                                        </MKTypography>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <MKTypography color='dark' variant="body2"
                                                                      fontWeight="small"
                                                                      textTransform="capitalize"
                                                                      sx={{mb: 0, mt: 0}}>
                                                            {type.type}
                                                            <div className="text-sm font-light">{type.classification}</div>
                                                        </MKTypography>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <MKTypography color='dark' variant="body2"
                                                                      fontWeight="small"
                                                                      textTransform="capitalize"
                                                                      sx={{mb: 0, mt: 0}}>
                                                            {type.reference_no}
                                                        </MKTypography>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <MKTypography color='dark' variant="body2"
                                                                      fontWeight="small"
                                                                      textTransform="capitalize"
                                                                      sx={{mb: 0, mt: 0}}>
                                                            {type.control_no}
                                                        </MKTypography>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <MKTypography color='dark' variant="body2"
                                                                      fontWeight="small"
                                                                      textTransform="capitalize"
                                                                      sx={{mb: 0, mt: 0}}>
                                                            {type.created_at}
                                                        </MKTypography>
                                                    </TableCell>

                                                    <TableCell id={index}>
                                                        <MKTypography color='dark' variant="body2"
                                                                      fontWeight="small"
                                                                      textTransform="capitalize"
                                                                      sx={{mb: 0, mt: 0, float: 'right'}}>
                                                            {Number(type.current_price).toLocaleString()}
                                                        </MKTypography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <MKTypography color='dark' variant="body2"
                                                                      fontWeight="small"
                                                                      textTransform="capitalize"
                                                                      sx={{mb: -1, mt: -1, fontSize: 14}}>
                                                            <IconButton
                                                                disabled={loading}
                                                                color="dark"
                                                                aria-label="Action Button"
                                                                id={type.id}
                                                                onClick={handleOpenEdit}
                                                            >
                                                                {type.completed === 1 ?
                                                                    'Completed'
                                                                    :
                                                                    <EditNoteOutlined color='warning'/>
                                                                }

                                                            </IconButton>
                                                        </MKTypography>
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        }

                                    </MKBox>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[20, 50, 100, 200]} // You can customize this
                                component="div"
                                count={filteredData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>

                    }

                <CompletedRegistrationSummaryModal summary={summary} open={open} handleClose={handleClose}/>
                <EditRegistrationModal open={openEdit} handleClose={handleClose} participant={selectedRow}
                                       getParticipants={getApprovals}/>
            </div>


        </>
    );
}

export default IncompleteRegistration;
