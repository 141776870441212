import React from "react";

import {Divider} from "antd";

import {Link} from "react-router-dom";
import appLogo from "../../assets/images/logo/tz.png";


function LeftPanel() {


    return (

        <div className="fixed h-full mt-16 left-0 w-52 bg-gray-100 rounded-r-xl p-4 z-50">
            <div className="flex flex-col h-full">

                <Divider className="mt-1 mb-1"/>
                <div className="flex flex-col space-y-2">
                    <Link
                        to="/presentation"
                        className="bg-gray-400 hover:bg-gray-300  text-gray-800 text-sm px-2 py-1 rounded-md text-center"
                    >
                        Home
                    </Link>
                    <Divider/>
                    <Link
                        to="/registration"
                        className="bg-gray-400 hover:bg-gray-300  text-gray-800 text-sm px-2 py-1 rounded-md text-center"
                    >
                        <div className="font-bold">Registration Details</div><div className="font-light">Taarifa za Usajili</div>
                    </Link>
                    <Link
                        to="/registration"
                        className="bg-gray-400 hover:bg-gray-300 text-gray-800 text-sm px-2 py-1 rounded-md text-center"
                    >
                        <div className="font-bold">Payment Details</div><div className="font-light">Taarifa za Malipo</div>
                    </Link>
                    <Link
                        to="/login"
                        className="bg-gray-400 hover:bg-gray-300 text-gray-800 text-sm px-2 py-1 rounded-md text-center"
                    >
                        <div className="font-bold">Contact Support Team</div><div className="font-light">Omba Msaada</div>
                    </Link>

                </div>

            </div>
        </div>


    );
}

export default LeftPanel;
