/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
// ImageSlider Us page sections
// Routes
// Images
import React, {useEffect, useState} from "react";
import {Config} from "../../utils/Config";
import Divider from "@mui/material/Divider";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import {CircularProgress, Table, TableCell, TableContainer, TableRow} from "@mui/material";
import MKButton from "../../components/MKButton";
import CompletedRegistrationSummaryModal from "./CompletedRegistrationSummaryModal";

function CompletedRegistration() {
    let [loading, setLoading] = useState(false);
    let [businessTypes, setBusinessTypes] = useState([]);
    let [summary, setSummary] = useState([]);

    const [open, setOpen] = useState(false);
    const [openApproval, setOpenApproval] = useState(false);
    const [documentLinks, setDocumentLinks] = useState([]);
    const [links, setLinks] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };
    const handleOpenApproval = (e) => {
        const id = e.currentTarget.getAttribute('id');

        const typeRow = businessTypes[id];
        const linkData = {
            registration_form: `${links}${typeRow.id}`,
            payment_form: typeRow.payment_receipt,
            applicationId: typeRow.id
        }
        setDocumentLinks(linkData);
        setOpen(false);
        setOpenApproval(true);
    };

    useEffect(() => {
        if (open) {
            setDocumentLinks(documentLinks);
        }
    }, [handleOpenApproval]);

    const handleClose = () => {
        setOpen(false);
        setOpenApproval(false);
        getApprovals().then(r => false)
    };

    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    const getApprovals = async (payLoad) => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'completed-registration', headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setBusinessTypes(res.data.data.events);
                setSummary(res.data.data.summary)
                setLinks(res.data.data.link)
            }

            setLoading(false)
        }
    }
    useEffect(() => {
        getApprovals().then(r => false);
    }, []);

    useEffect(() => {
        setBusinessTypes(businessTypes);
        setSummary(summary)
    }, [businessTypes]);
    useEffect(() => {
        setDocumentLinks(documentLinks);
    }, [documentLinks]);
    useEffect(() => {
        setLinks(links);
    }, [links]);

    const sumAmounts = () => {
        let total = 0;
        try {
            total = summary.reduce((total, record) => total + parseFloat(record.amount), 0);
        } catch (e) {

        }
        return total;
    };
    const sumCounts = () => {
        let total = 0;
        try {
            total = summary.reduce((total, record) => total + parseFloat(record.count), 0);
        } catch (e) {

        }
        return total;
    };

    const formatedNumber = (number) => {
        return Number(number).toLocaleString('en-US', {
            style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2,
        });
    }


    return (
        <>
            <div sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <MKTypography sx={{mb: 1, textAlign: 'right !important', mr: 9}} color='secondary' variant="body2">
                    Count: <b>{sumCounts()}</b> &nbsp; | &nbsp; Total
                    Amount: <b>{formatedNumber(sumAmounts())} TZS</b> &nbsp; | &nbsp;
                    <MKButton onClick={handleOpen} color='secondary'>View Summary</MKButton>
                </MKTypography>
            </div>
            <Divider sx={{mt: -1}}/>


            {loading && <CircularProgress/>}
            {businessTypes && Number(businessTypes.length) > 0 &&
                <div className="px-2">

                    <TableContainer>
                        <Table aria-label="simple table">
                            <MKBox color='dark' component="thead">
                                <TableRow>
                                    <TableCell>
                                        <b>SN</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>NAME</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>BUSINESS TYPE</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>CLASSIFICATION</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>REFERENCE</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>REGISTRATION DATE</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>PHONE</b>
                                    </TableCell>
                                    <TableCell align="center">
                                        <b>VERIFIED BY</b>
                                    </TableCell>
                                    <TableCell align="center">
                                        <b>AMOUNT</b>
                                    </TableCell>

                                </TableRow>
                            </MKBox>
                            <MKBox color='text' component="tbody">
                                {businessTypes && businessTypes.map((type, index) =>
                                    <TableRow color='dark' key={type.id}>
                                        <TableCell component="th" scope="row">
                                            <MKTypography color='secondary' variant="body2"
                                                          fontWeight="small"
                                                          textTransform="capitalize"
                                                          sx={{mb: 0, mt: 0}}>
                                                {index + 1}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <MKTypography color='dark' variant="body2"
                                                          fontWeight="small"
                                                          textTransform="capitalize"
                                                          sx={{mb: 0, mt: 0}}>
                                                {type.name}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <MKTypography color='dark' variant="body2"
                                                          fontWeight="small"
                                                          textTransform="capitalize"
                                                          sx={{mb: 0, mt: 0}}>
                                                {type.type}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <MKTypography color='dark' variant="body2"
                                                          fontWeight="small"
                                                          textTransform="capitalize"
                                                          sx={{mb: 0, mt: 0}}>
                                                {type.classification}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <MKTypography color='dark' variant="body2"
                                                          fontWeight="small"
                                                          textTransform="capitalize"
                                                          sx={{mb: 0, mt: 0}}>
                                                {type.reference_no}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <MKTypography color='dark' variant="body2"
                                                          fontWeight="small"
                                                          textTransform="capitalize"
                                                          sx={{mb: 0, mt: 0}}>
                                                {type.created_at}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <MKTypography color='dark' variant="body2"
                                                          fontWeight="small"
                                                          textTransform="capitalize"
                                                          sx={{mb: 0, mt: 0}}>
                                                {type.phone}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <MKTypography color='info' variant="body2"
                                                          fontWeight="small"
                                                          textTransform="capitalize"
                                                          sx={{mb: 0, mt: 0}}>
                                                {type.verified_by}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell id={index}>
                                            <MKTypography color='black' variant="body2"
                                                          fontWeight="small"
                                                          textTransform="capitalize"
                                                          sx={{mb: 0, mt: 0, float: 'right'}}>
                                                {Number(type.current_price).toLocaleString()}
                                            </MKTypography>
                                        </TableCell>

                                    </TableRow>
                                )
                                }

                            </MKBox>
                        </Table>
                    </TableContainer>

                </div>
            }
            <CompletedRegistrationSummaryModal summary={summary} open={open} handleClose={handleClose}/>


        </>
    );
}

export default CompletedRegistration;
