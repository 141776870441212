import React, {useEffect, useState} from "react";

const ImageSlider = () => {

    const images = [
        {
            src: 'https://madinigeita.co.tz/maonesho-api/storage/app/public/images/sliders/mn0.jpg',
            title: 'Slide 1',
            label: 'Mh. Dr. Dotto Mashaka Biteko Naibu Waziri Mkuu na Waziri wa Nishati akipokea zawadi kutoka kwa washiriki wa maonesho',
        },
        {
            src: 'https://madinigeita.co.tz/maonesho-api/storage/app/public/images/sliders/mn1.jpg',
            title: 'Slide 2',
            label: 'Wajasiriliamali katika maonesho',
        },
        {
            src: 'https://madinigeita.co.tz/maonesho-api/storage/app/public/images/sliders/mn2.jpg',
            title: 'Slide 5',
            label: 'Wajasiriliamali katika maonesho',
        },
        {
            src: 'https://madinigeita.co.tz/maonesho-api/storage/app/public/images/sliders/mn3.jpg',
            title: 'Slide 3',
            label: 'Wajasiriliamali katika maonesho',
        },
        {
            src: 'https://madinigeita.co.tz/maonesho-api/storage/app/public/images/sliders/mn4.jpg',
            title: 'Slide 4',
            label: 'Makampuni mbali mbali katika maonesho',
        },
        {
            src: 'https://madinigeita.co.tz/maonesho-api/storage/app/public/images/sliders/mn5.jpg',
            title: 'Slide 5',
            label: 'Sales Report for a given period',
        },
        {
            src: 'https://madinigeita.co.tz/maonesho-api/storage/app/public/images/sliders/mn6.jpg',
            title: 'Slide 5',
            label: 'Makampuni mbali mbali katika maonesho',
        },
        {
            src: 'https://madinigeita.co.tz/maonesho-api/storage/app/public/images/sliders/mn7.jpg',
            title: 'Slide 6',
            label: 'Makampuni mbali mbali katika maonesho',
        },
        {
            src: 'https://madinigeita.co.tz/maonesho-api/storage/app/public/images/sliders/mn8.jpg',
            title: 'Slide 7',
            label: 'Makampuni mbali mbali katika maonesho',
        },
        {
            src: 'https://madinigeita.co.tz/maonesho-api/storage/app/public/images/sliders/mn9.jpg',
            title: 'Slide 8',
            label: 'Makampuni mbali mbali katika maonesho',
        },
    ];

    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        images.map((imageSrc, index) => {
            const img = new Image();
            img.src = imageSrc;
            img.onload = () => handleImageLoad(index);
            return img;
        });
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [images]);

    const handleImageLoad = (index) => {
        console.log(`Image ${index + 1} loaded`);
    };

    const handleNext = () => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentImage((prevImage) => (prevImage - 1 + images.length) % images.length);
    };
    return (
        <div className="bg-gray-100 rounded-md">
            <div className="flex items-center justify-center rounded-md col-span-2">
                {/* Image Slider */}
                <div
                    className="image-slider "
                    style={{
                        position: 'relative',
                        width: '99%',
                        height: '100%',
                        borderRadius: '5px',
                        overflow: 'hidden',
                    }}
                >
                    {/* Previous Button */}
                    <button
                        className="nav-button prev"
                        onClick={handlePrev}
                        style={{
                            position: 'absolute',
                            top: '95%',
                            left: 0,
                            transform: 'translateY(-50%)',
                            background: 'rgba(255, 255, 255, 0.5)',
                            border: 'none',
                            padding: '5px',
                            cursor: 'pointer',
                            transition: 'opacity 0.5s ease-in-out',
                            opacity: 0.7,
                            zIndex: 1, // Adjust the z-index
                            backgroundColor: '#365e7d',
                            color: 'white',
                            borderRadius: '5px'
                        }}
                    >
                        &lt; Prev
                    </button>

                    {/* Image Container */}
                    <div
                        className="image-container"
                        style={{
                            display: 'flex',
                            width: `${images.length * 100}%`,
                            transition: 'transform 0.5s ease-in-out',
                            transform: `translateX(-${currentImage * (100 / images.length)}%)`,
                        }}
                    >
                        {/* Images */}
                        {images.map((image, index) => (
                            <div
                                key={index}
                                style={{
                                    width: `100vw`,
                                    height: '100%',
                                    objectFit: 'cover',
                                    position: 'relative',
                                }}
                            >
                                <img
                                    src={image.src}
                                    alt={image.title}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        marginTop: '25px',
                                    }}
                                />
                                <div
                                    className="image-label"
                                    style={{
                                        position: 'absolute',
                                        top: '0px',
                                        left: 0,
                                        width: '100%',
                                        textAlign: 'center',
                                        color: '#365e7d',
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {image.label}
                                </div>

                            </div>
                        ))}
                    </div>

                    {/* Next Button */}
                    <button
                        className="nav-button next"
                        onClick={handleNext}
                        style={{
                            position: 'absolute',
                            top: '95%',
                            right: 0,
                            transform: 'translateY(-50%)',
                            background: 'rgba(255, 255, 255, 0.5)',
                            opacity: 0.7,
                            border: 'none',
                            padding: '5px',
                            cursor: 'pointer',
                            backgroundColor: '#365e7d',
                            color: 'white',
                            borderRadius: '5px',
                        }}
                    >
                        Next &gt;
                    </button>
                </div>
            </div>
        </div>

    );
}

export default ImageSlider