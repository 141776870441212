import React, {useEffect, useState} from 'react';
import {Divider, Modal} from 'antd';

function BusinessTypeFormModal(props) {

    const [selectedCategory, setSelectedCategory] = useState([]);

    const [formData, setFormData] = useState({
        id: props.businessType ? props.businessType.id : '',
        name: props.businessType ? props.businessType.name : '',
        description: props.businessType ? props.businessType.description : '',
        current_price: props.businessType ? props.businessType.current_price : '',
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        // Handle other form field changes if needed
        setFormData((prevData) => ({...prevData, [name]: value}));

    };

    useEffect(() => {
        setSelectedCategory(selectedCategory);
    });

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        // Submit the form data
        props.onSubmit(formData);
        props.closeModal(); // Close the modal after submission
    };

    const categoryOptions = props.categories;

    return (
        <Modal
            title={'Edit Business Type'}
            open={props.isOpen}
            onCancel={props.closeModal}
            width={'60%'}
            style={{
                top: 20,
            }}
            footer={<></>}
        >
            <Divider/>
            <form onSubmit={handleSubmit}>
                <div className="modal-container grid grid-cols-2 gap-4">

                    <div className="mb-1">
                        <label className="label">
                            Name
                            <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            readOnly
                            disabled
                            className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-1">
                        <label className="label">
                            Current Price
                            <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="number"
                            name="current_price"
                            value={formData.current_price}
                            onChange={handleChange}
                            required
                            className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-1 col-span-2">
                        <label className="label">
                            Description
                            <span className="text-red-500">*</span>
                        </label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            required
                            className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>


                    {/* Add more fields individually as needed */}
                </div>
                <div className="modal-footer flex justify-end mt-3">
                    <button
                        type="submit"
                        className="bg-[#365e7d] border-0 text-xs text-white px-3 py-2 rounded-md"
                    >
                        {'Save'}
                    </button>
                </div>
            </form>
        </Modal>
    );
}

// Properties
BusinessTypeFormModal.defaultProps = {
    isOpen: false,
    closeModal: function () {
    },
    onSubmit: (formData) => {
    },
};

export default BusinessTypeFormModal;
