import React, {useEffect, useState} from 'react';
import {List, Modal} from '@mui/material';
import ParticipantForm from './ParticipantForm';
import Slide from "@mui/material/Slide";
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import MKButton from "../../components/MKButton";
import {Config} from "../../utils/Config";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";
import Swal from "sweetalert2";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import EditParticipantForm from "./EditParticipantForm";
import swalWithBootstrapButtons from "sweetalert2";

const EditRegistrationModal = ({open, handleClose, participant,getParticipants}) => {
    const [tin, setTin] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [amount, setAmount] = useState(0);
    const [benefits, setBenefits] = useState('');
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(false);



    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();


    useEffect(() => {
        setErrors(errors);
    }, [errors]);

    useEffect(() => {
        setName(participant.name);
        setAmount(participant.current_price);
        setBenefits(participant.benefits);
        setTin(participant.tin);
        setPhone(participant.phone);
    }, [participant]);



    const handleParticipantChange = (e) => {
        const {name, value} = e.target;
        if (name === 'amount') {
            setAmount(value);
        } else if (name === 'benefits') {
            setBenefits(value);
        }
    };

    const handleSubmit = () => {
        // Handle form submission and data validation
        const payLoad = {
            id: participant.id,
            amount: amount,
            benefits: benefits,
            created_by: profile.id,
        }
        console.log(payLoad);
        updateParticipant(payLoad).then(r => false);

    };

    const updateParticipant = async (payLoad) => {
        setLoading(true)
        setErrors([]);

        const res = await Axios.post(apiURL + 'update-participant', payLoad,headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            try {
                if (status === 101) {
                    const id = res.data.data.id;
                    handleClose();
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn btn-success', cancelButton: 'btn btn-danger'
                        }, buttonsStyling: true
                    })

                    getParticipants();
                    swalWithBootstrapButtons.fire({
                        title: 'Success',
                        text: message,
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            window.open(`https://madinigeita.co.tz/maonesho-api/public/api/participant-details/${id}`, '_blank');
                        }
                    })

                }else if(status === 103){
                    handleClose();
                    swalWithBootstrapButtons.fire({
                        title: 'Restricted',
                        text: message,
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            getParticipants();
                        }
                    })
                } else {
                    setErrors(message);
                }
            }catch (e) {
                
            }

            setLoading(false)
        }
    }

    return (<Modal open={open} onClose={handleClose} sx={{display: "grid", placeItems: "center"}}>
        <Slide direction="down" in={open} timeout={500}>
            <div sx={{maxHeight: '80vh', overflow: 'auto'}}>
                <MKBox
                    position="relative"
                    width="650px"
                    maxHeight="85vh"
                    overflow="auto"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                >
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                        <MKTypography variant="h5">Exhibitor registration / Usajili wa mshiriki wa maonesho</MKTypography>
                        <CloseIcon fontSize="medium" sx={{cursor: "pointer"}} onClick={handleClose}/>
                    </MKBox>
                    <Divider sx={{my: 0}}/>
                    {errors && errors.map((record, index) => <List>
                        <MKTypography sx={{ml: 2, mr: 2, mt: 0}} id={index} variant="body2" color="error"
                                      fontWeight="light">
                            <b>{record.label}:&nbsp;</b>{record.value}<br/>
                        </MKTypography>
                    </List>)}
                    <MKBox p={2}>
                        <EditParticipantForm
                            tin={tin}
                            name={name}
                            phone={phone}
                            amount={amount}
                            benefits={benefits}
                            handleChange={handleParticipantChange}
                        />
                    </MKBox>
                    <Divider sx={{my: 0}}/>
                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                        <MKButton variant="gradient" color="secondary" onClick={handleClose}>
                            close
                        </MKButton>
                        <MKButton onClick={handleSubmit} variant="gradient" color="warning">
                            Save / Kamilisha usajili
                        </MKButton>
                    </MKBox>
                </MKBox>
            </div>
        </Slide>
    </Modal>);
};

export default EditRegistrationModal;