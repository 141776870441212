/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 React examples
// ImageSlider Us page sections
// Routes
// Images
import React, {useEffect, useState} from "react";
import MKButton from "../../../components/MKButton";
import {CircularProgress, Table, TableCell, TableContainer, TableRow} from "@mui/material";
import {AddAlertOutlined} from "@mui/icons-material";
import {Divider} from "antd";
import {GetProfile, GetTokenHeaders,numFormat} from "../../../common/ReusableFunction";
import Axios from "axios";
import AlertMessages from "../../../utils/AlertMessage";
import {Config} from "../../../utils/Config";
import ControlNoFormModal from "./controlNoForm";


function ControlNumbers() {
    const [loading, setLoading] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [participant, setParticipant] = useState([]);
    const apiURL = Config.apiURL;

    const [sortedProducts, setSortedProducts] = useState(participants);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({key: null, direction: 'asc'});

    const [isModalOpen, setModalOpen] = useState(false);
    const [open, setOpen] = useState(false);

    const handleSort = (key) => {
        let direction = 'asc';

        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }

        const sorted = [...sortedProducts].sort((a, b) => {
            const valueA = a[key].toLowerCase();
            const valueB = b[key].toLowerCase();

            if (valueA < valueB) return direction === 'asc' ? -1 : 1;
            if (valueA > valueB) return direction === 'asc' ? 1 : -1;
            return 0;
        });

        setSortedProducts(sorted);
        setSortConfig({key, direction});
    };

    let headers = GetTokenHeaders();

    const getPendingControlNumbers = async (payLoad) => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'pending-control-numbers', headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setParticipants(res.data.data.participants);
                setSortedProducts(res.data.data.participants);
            }

            setLoading(false)
        }
    }
    useEffect(() => {
        getPendingControlNumbers().then(r => false);
    }, []);
    const sortIcons = (key) => {
        if (sortConfig.key === key) {
            return <span>
              {sortConfig.direction === 'asc' ? ' ▲' : ' ▼'}
            </span>
        }
        return '';
    }
    const handleSearch = (e) => {
        setSearchTerm(e.target.value.toLowerCase());

        if (e.target.value === '') {
            setSortedProducts(participants);
            return;
        }
        const filtered = participants.filter((product) =>
            Object.values(product).some(
                (value) => typeof value === 'string' && value.toLowerCase().includes(searchTerm)
            )
        );

        setSortedProducts(filtered);
    };

    const openModal = () => {
        setModalOpen(true);
        // getCategories()
    }
    const closeModal = () => {
        setModalOpen(false);
    }
    const profile = GetProfile();
    const handleCreateControlNo = (formData) => {
        const payLoad = {
            id:formData.id,
            control_no:formData.control_no,
            created_by: profile.id
        }
        updateControlNo(payLoad).then(r => false)
        // Handle form submission, e.g., make an API call
        console.log('Creating subscriber with data:', formData);
        // Close the modal after submission
        closeModal();
    };

    const updateControlNo = async (payLoad) => {
        setLoading(true)

        const res = await Axios.post(apiURL + 'update-control-no', payLoad,headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 101) {
                AlertMessages(status,message)

            } else {
                alert(message)
            }

            setLoading(false);
            getPendingControlNumbers().then(r => false);

        }
    }

    function handleControlNumber(index) {
        setParticipant(participants[index])
        openModal();
    }

    useEffect(()=>{
        setParticipant(participant)
    })

    const getTotals = (type, details) => {

        if (type === 'bills' && details === 'amount') {
            return sortedProducts.reduce((accumulator, item) => {
                return accumulator + Number(item.amount);
            }, 0);
        }

        return 0;
    };

    return (
        <div className="px-2">

            <div className="mt-2">
                <div className="text-lg font-bold">Pending Control Numbers</div>
                <Divider className="mt-1"/>

            {loading && <CircularProgress/>}
            {participants && participants.length > 0 &&
                <div className="bg-white px-6 py-3 rounded-md">
                    <table className={`min-w-full divide-y divide-gray-200`}>
                        <thead className="bg-gray-50">
                        <tr>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                SN
                            </th>
                            <th
                                scope="col"
                                onClick={() => handleSort('partcipant_name')}
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Name {sortIcons('partcipant_name')}
                            </th>
                            <th
                                scope="col"
                                onClick={() => handleSort('reference')}
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Reference {sortIcons('reference')}
                            </th>
                            <th
                                scope="col"
                                onClick={() => handleSort('phone')}
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Phone {sortIcons('phone')}
                            </th>
                            {/*Quantity*/}
                            <th
                                scope="col"
                                onClick={() => handleSort('control_no')}
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Control No {sortIcons('control_no')}
                            </th>

                            <th
                                scope="col"
                                onClick={() => handleSort('amount')}
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Amount {sortIcons('amount')}
                            </th>
                            <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">Edit</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {sortedProducts &&
                            sortedProducts.map((product, index) => (
                                <tr key={product.id}>
                                    <td className="px-5 py-1 whitespace-nowrap text-sm text-gray-900">
                                        {index + 1}
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-900">

                                        <div className="text-sm">
                                            {product.partcipant_name}
                                        </div>
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-900">
                                        {product.reference_no}
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-900">
                                        {product.phone}
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-900">
                                        {product.control_no}
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-900 text-right">
                                        {numFormat(product.amount)}
                                    </td>
                                    <td className="px-4 py-1 whitespace-nowrap text-right text-sm font-medium">
                                        <button

                                            id={product.id}
                                            onClick={()=>{
                                                handleControlNumber(index)
                                            }}
                                            className="text-white px-2 py-1 rounded-md border-0 bg-yellow-700 hover:bg-gray-600"
                                        >
                                            Add
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        <tr>
                            <td colSpan={5} className="text-right text-sm font-bold">Total</td>
                            <td className="text-sm px-2 py-1 text-right  font-bold">
                                {numFormat(getTotals('bills', 'amount'))}
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            }
            </div>
            {isModalOpen && <ControlNoFormModal
                isOpen={isModalOpen}
                closeModal={closeModal}
                onSubmit={handleCreateControlNo}
                participant={participant}
            />}
        </div>
    );
}

export default ControlNumbers;
