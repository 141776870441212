import React, {useEffect, useState} from 'react';
import {CardContent, Modal} from '@mui/material';
import Slide from "@mui/material/Slide";
import MKBox from "../../components/MKBox";
import MKButton from "../../components/MKButton";
import {Config} from "../../utils/Config";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import Grid from "@mui/material/Grid";
import ViewRegistrationForm from "./ViewRegistrationForm";
import Container from "@mui/material/Container";

const PaymentApprovalModal = ({open, handleClose, documentLinks}) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState();
    const [price, setPrice] = useState(0);
    let [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState([]);

    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    useEffect(() => {
        setErrors(errors);
    }, [errors]);
    const handleParticipantChange = (e) => {
        const {name, value} = e.target;
        if (name === 'name') {
            setName(value);
        } else if (name === 'description') {
            setDescription(value);
        } else if (name === 'price') {
            setPrice(value);
        }
    };

    const handleSubmit = () => {
        // Handle form submission and data validation
        console.log('Submitted');
        const payLoad = {
            name: name,
            description: description,
            current_price: price,
            created_by: profile.id,
        }
        console.log(payLoad);
        registerBusinessType(payLoad).then(r => false);

        // Reset form fields
        setName('');
        setDescription('');
        setPrice(0);
        // Close the modal
    };

    const registerBusinessType = async (payLoad) => {
        setLoading(true)
        setErrors([]);
        const res = await Axios.post(apiURL + 'create-business-type', payLoad, headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 101) {
                const id = res.data.data.id;
                handleClose();
                AlertMessages(status, message)

            } else {
                setErrors(message);
            }

            setLoading(false)
        }
    }
    const ApprovePayment = async (e) => {
        const id = e.target.getAttribute('id');
        const payLoad = {
            applicationId: documentLinks.applicationId,
            created_by: profile.id,
            id: id
        }
        setLoading(true)
        setErrors([]);
        const res = await Axios.post(apiURL + 'verify-payment', payLoad, headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 101) {
                const id = res.data.data.id;
                handleClose();
                AlertMessages(status, message)

            } else {
                setErrors(message);
            }

            setLoading(false)
        }
    }


    return (<Modal open={open} onClose={handleClose} sx={{display: "grid", placeItems: "center"}}>
        <Slide direction="down" in={open} timeout={500}>
            <div sx={{maxHeight: '80vh', overflow: 'auto'}}>
                <MKBox
                    position="relative"
                    width="200px"
                    maxHeight="85vh"
                    overflow="auto"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                >
                    {documentLinks.registration_form &&
                        <MKBox pt={1} px={1} mt={2}>

                            <CardContent>
                                <Grid container spacing={1}>

                                    {/*<Grid item xs={10}>*/}
                                    {/*    <Grid sx={{mt: 1}} item xs={12}>*/}
                                    {/*        <ViewRegistrationForm*/}
                                    {/*            documentLink={documentLinks.payment_form}*/}
                                    {/*            message='Hakuna taarifa za malipo'/>*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid sx={{mt: -8}} item xs={12}>*/}
                                    {/*        <ViewRegistrationForm*/}
                                    {/*            documentLink={documentLinks.registration_form}*/}
                                    {/*            message='Hakuna taarifa za usajili'/>*/}
                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}
                                    <Grid item xs={12}>
                                        <Container span={12}>
                                            <MKButton sx={{mt: 2, ml: -5, mr: -10}} onClick={ApprovePayment}
                                                      variant="gradient" color="warning">
                                                Verify Payment
                                            </MKButton>

                                        </Container>
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </MKBox>
                    }
                </MKBox>
            </div>
        </Slide>
    </Modal>);
};

export default PaymentApprovalModal;